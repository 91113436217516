<template>
 <main class="home">
  <Header />
  <div class="LandingPage">
 
   <div class="container">
     <div class="container">

     <div class="container">
      <section class="container mt-5 mb-5">
        <div class="search-box">
          <form class="d-flex" action="." @submit.prevent="submitSearch" id="Search">
              <input aria-labelledby="Search" class="fl" type="search"  placeholder="Enter your search term"  ref="searchTermsTxt" v-model="searchTermsTxt" >
             
                <button class="fl btn" @click="submitSearch">Search</button>
          </form>
        </div> 
      </section>
    </div>   
    <section class="container mt-5">
      <div id="searchContainer">
        <div id="outerFlow">
          <div id="innerFlowOne">
            <div class="searchText">
              <p class="searchText" v-if ="language && language.name === 'english'">Search Results for: </p>
              <p class="searchText" v-if="language && language.name === 'welsh'">Canlyniadau chwilio ar gyfer: </p>
              <h4 class="searchText">{{ searchTerm }}</h4>
            </div>
          </div>
        </div>
      </div>
    </section>
 <section class="container">
        <div id="searchResultsContainer">

          <div class="searchResultOuterContainer" v-for="item in searchResults" :key="item.ref">

            <div class="searchResultContainer" v-if="item.index <= shownItems">
              <div class="searchResultText">
                <p class="searchResultTitle">{{item.title}}</p>
                <p class="searchResultDescription">{{item.description}}</p>
              </div>
              <div class="searchResultButton">
                <a class="searchResultButton" :href="item.url" target="_blank">View Page</a>
              </div>
            </div>
          </div>
        </div>
</section>
</div>
</div>
</div>
 

      <section class="footer">
              <footer>
                <cookie-law theme="dark-lime"></cookie-law>
              </footer>
              <Footer/>
      </section>
    </main>
</template>
<script>
import Header from "../components/Header";
import Footer from "../components/Footer";
import { mapGetters } from "vuex";
var _ = require('lodash');
export default {
  name: "Search",
  data: function () {
    return {
      
     iconDisplayStyle:'none',
      searchTermsTxt:"",
     searchTerm: "",
      searchResults: [],
      shownItems: 0,
      totalItems: 0
    }
  },
  components: {
  Header,
  Footer,
 
  },
   computed: {
    ...mapGetters(["content","language"]),
    },

   methods: {

      scrollToTop() {
          window.scrollTo({top: 0,left: 0, behavior: 'smooth'});
 
      },
    

    KeyWordsList(keyWordArray){
      let arr = [];
      if(keyWordArray.length>0){
          keyWordArray.forEach((value, index) => {
              arr[index]=value.word;
          });
          return JSON.stringify(arr);
      }else{
        return arr;
      }
  },
        submitSearch()
        {
           let searchFor = this.$refs.searchTermsTxt.value;
           this.searchTerm = searchFor;
      this.searchFor(searchFor);
    },
    
      searchFor(terms){
      this.searchTerm = terms;
      let counter = 0;
      if (terms && /\S/.test(terms)) {

        let resultsF = [];
        let allSearchTerms = terms.toLowerCase().trim().split(" ");
        allSearchTerms.forEach(term => {
          if(term !== " "){
           let matchesF = [];
            if(this.content && this.content.pageContent){
              matchesF = this.content.pageContent.filter( a => {
                return (a.title !== null && a.title !== undefined && a.title.toLowerCase().indexOf(term) > -1)
                || (a.desc !== null && a.desc !== undefined && a.desc.toLowerCase().indexOf(term) > -1)
                
                }
              );
            }
            matchesF.forEach(match => {
              let prefix = "F";
              let Desc=match.desc.toString().replace( /(<([^>]+)>)/ig, '');
              let linkedPage= match.linkedPage[0].pageSlug;
              let url = "#/"+linkedPage;
              if(resultsF.filter(a => { return a.ref === prefix + match.Id; }).length == 0){
                resultsF.push({
                  ref: prefix + match.Id,
                  title: match.title ? match.title : match.title,
                  description: Desc,
                  url: url,
                  index: counter
                });
                counter++;
              }
            });
            let resultsP = [];
           let matchesP = [];
            if(this.content && this.content.cardsContent){
              matchesP = this.content.cardsContent.filter( a => {
                return (a.cardTitle !== null && a.cardTitle !== undefined && a.cardTitle.toLowerCase().indexOf(term) > -1)
                || (a.cardDescription !== null && a.cardDescription !== undefined && a.cardDescription.toLowerCase().indexOf(term) > -1)
                   }
              );

              
            }
            matchesP.forEach(match => {
              let prefix = "P";
             let url = "#/consultation";
               let Desc=match.cardDescription.toString().replace( /(<([^>]+)>)/ig, '');
              
              if(resultsP.filter(a => { return a.ref === prefix + match.Id; }).length == 0){
                resultsP.push({
                  ref: prefix + match.Id,
                  title: match.cardTitle,
                  description: Desc,
                  url: url,
                  index: counter
                });
                counter++;
              }
            });
Array.prototype.push.apply(resultsF,resultsP);

             let resultsD = [];
            let matchesD = [];
            if(this.content && this.content.timeline){
              matchesP = this.content.timeline.filter( a => {
                return (a.header !== null && a.header !== undefined && a.header.toLowerCase().indexOf(term) > -1)
                    || (a.text !== null && a.text !== undefined && a.text.toLowerCase().indexOf(term) > -1)
                     }
              );
            }
            matchesD.forEach(match => {
              let prefix = "D";
             let url = "";
               let Desc=match.text.toString().replace( /(<([^>]+)>)/ig, '');
              
              if(resultsD.filter(a => { return a.ref === prefix + match.Id; }).length == 0){
                resultsD.push({
                  ref: prefix + match.Id,
                  title: match.header,
                  description:Desc,
                 // url: url,
                  index: counter
                });
                counter++;
              }
            });
            
Array.prototype.push.apply(resultsF,resultsD);
  let resultsE = [];
            let matchesE = [];
            if(this.content && this.content.blog){
              matchesE = this.content.blog.filter( a => {
                return (a.newsCardTitle !== null && a.newsCardTitle !== undefined && a.newsCardTitle.toLowerCase().indexOf(term) > -1)
                    || (a.blogDescription !== null && a.blogDescription !== undefined && a.blogDescription.toLowerCase().indexOf(term) > -1)
                     }
              );
            }
            matchesE.forEach(match => {
              let prefix = "D";
            let url = "#/news";
               let Desc=match.blogDescription.toString().replace( /(<([^>]+)>)/ig, '');
              
              if(resultsE.filter(a => { return a.ref === prefix + match.Id; }).length == 0){
                resultsE.push({
                  ref: prefix + match.Id,
                  title: match.newsCardTitle,
                  description:Desc,
                  url: url,
                  index: counter
                });
                counter++;
              }
            });
            
Array.prototype.push.apply(resultsF,resultsE);

let resultsLD = [];
            let matchesLD = [];
            if(this.content && this.content.libData){
              matchesLD = this.content.libData.filter( a => {
               
               
                return (a.fileName !== null && a.fileName !== undefined && a.fileName.toLowerCase().includes(term))|| (a.keyWords.length>0 && (this.KeyWordsList(a.keyWords).toLowerCase().includes(term)  ))
                     }

                    
              );
            }
            matchesLD.forEach(match => {
              let prefix = "LD";
            let url = "#/document-library";
               let Desc="";
              
              if(resultsLD.filter(a => { return a.ref === prefix + match.id; }).length == 0){
                resultsLD.push({
                  ref: prefix + match.id,
                  title: match.fileName,
                  description:Desc,
                  url: url,
                  index: counter
                });
                counter++;
              }
            });
                        
Array.prototype.push.apply(resultsF,resultsLD);


          }   
        });
        this.searchResults = resultsF;
        this.totalItems = resultsF.length;
        this.shownItems = 10;
      }else{
        this.searchResults.splice(0);
        this.shownItems = 0;
        this.totalItems = 0;
      }
    },
   },
    
  mounted() {
    this.scrollToTop()
  },


};
</script>

<style lang="scss">
#searchContainer{
    visibility: visible;
}

a.searchHomeLink {
    visibility: visible;
    height: 3.14em;
    text-decoration: none; 
    display: inline-block;
    display: flex;
    justify-content: center;
    margin-bottom: 2em;
    background-color: #005A84;
    border: 1px solid #005A84;
    color: #ffffff;
    margin-top: 40px;
    padding: 12px;
        border-radius: 40px;

    @media screen and (max-width: 768px){
        width: 100%;
    }
    
    @media screen and (min-width: 769px){
        width: 200px;
    }

    

    &:hover {
        background-color: #00A2DB;
        border: 1px solid white ;
        color: white;
        cursor: pointer;
    }

    &:active {
        background-color: #00A2DB;
        border: 1px solid white ;
        color: white;
        cursor: pointer;
    }
}

#outerFlow{
    //display: flex;

margin-bottom: 40px;
    @media screen and (max-width: 768px){
        flex-direction: column;
    }
    
    @media screen and (min-width: 769px){
        flex-direction: row;
    }
}

#innerFlowOne{
    @media screen and (max-width: 768px){
        flex-basis: 100%;
    }
    
    @media screen and (min-width: 769px){
        flex-basis: 59%;
    }
}

#innerFlowTwo{
    @media screen and (max-width: 768px){
        flex-basis: 100%;
    }
    
    @media screen and (min-width: 769px){
        flex-basis: 15%;
    }
}


.search-box form{
  justify-content: center;
}


div.searchText{
    margin-top: 0.5em;
    visibility: visible;
    color:#1E1E1E!important;
    
}


p.searchText{
    visibility: visible;
    font-size: 16px;
    font-weight:400;
  color:#1E1E1E;
}

h4.searchText{
    visibility: visible;
    font-size: 40px;
    font-weight:600;
    font-family: DM Serif Text;
}
p{
    visibility: visible;
    font-size: 16px;
    color:#1E1E1E;

}
#searchResultsContainer {
    display: block;
}

.nav-bar-search-txtbox {
    border: 0px transparent solid;
}


// 1024px width
li.search-nav-bar-pull-left{

    white-space: nowrap;
    
    @media screen and (max-width: 1024px){
        margin-left: calc(-1.0 * (5px + 10vw));
    }
    
    @media screen and (min-width: 1025px){
        margin-left: calc(-1.0 * (5px + 60vw));
    }

    button.do-search-link {

        visibility: visible;
        height: 40px;
        text-decoration: none;
        margin-top: -0.5em;
        margin-left: 0.25em;
    
        @media screen and (max-width: 768px){
            width: 5rem;
        }
        
        @media screen and (min-width: 769px){
            width: 5rem;
        }

        background-color: #00A2DB;
        border: 1px solid #00A2DB;
        color: black;
    
        &:hover {
            background-color: white;
            border: 1px solid black;
            color: #00A2DB;
            cursor: pointer;
        }
    
        &:active {
            background-color: white;
            border: 1px solid black;
            color: #00A2DB;
            cursor: pointer;
        }

    }

}


.searchResultContainer {
    padding-bottom: 1em;
    margin-bottom: 1em;
    border-bottom: 1px #2A8476 solid;
    display: flex;

    @media screen and (max-width: 768px){
        flex-direction: column;
    }
    
    @media screen and (min-width: 769px){
        flex-direction: row;
    }
}

div.searchResultText {

    @media screen and (max-width: 768px){
        flex-basis: 100%;
    }
    
    @media screen and (min-width: 769px){
        flex-basis: 85%;
    };
}

p.searchResultTitle{
    color: #00A2DB;
    text-align: left;
    font-weight: 600;
    font-size:27px;
    font-family: DM Serif Text;
}

p.searchResultDescription{
    color: #1A544B;
    text-align: left;
    font-weight: 400;
    font-size:16px;
    max-height: 70px;
    overflow: hidden;
}

div.searchResultButton {
    
    display: flex;

    @media screen and (max-width: 768px){
        flex-basis: 100%;
    }
    
    @media screen and (min-width: 769px){
        flex-basis: 15%;
    }
    
}

a.searchResultButton{
    visibility: visible;
    height: 3.14em;
    text-decoration: none;
    background-color: #2A8476;
    border: 2px solid #2A8476;
    color: white;
    display: inline-block;
    // this keeps the text centered
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    border-radius:8px;
    font-family: 'Museo';

    @media screen and (max-width: 768px){
        width: 100%;
    }
    
    @media screen and (min-width: 769px){
        width: 125px;
    }

    &:hover {
        background-color: transparent;
        border: 2px solid #2A8476;
        color: #2A8476;
        cursor: pointer;
    }

    &:active {
        background-color: transparent;
        border: 2px solid #2A8476;
        color: #2A8476;
        cursor: pointer;
    }
}


</style>


